import { IGetModelSchematicAsset } from "app/shared/schematics.service";
import { TreeNode } from "primeng/api/treenode";

export class SchematicAsset {
	id: number;
	asset: { id: number, title: string, typeId?: number, type?: string };

	constructor(data: IGetModelSchematicAsset) {
		this.asset = { id: data.asset.id, title: data.asset.title };
		this.id = data.id;
	}

	getTreeNode(): TreeNode {
		const root = {
			"label": this.asset.title,
			"data": `schematic-asset-${this.id}`,
			"expandedIcon": "mdi mdi-circle-box-outline",
			"collapsedIcon": "mdi mdi-circle-box-outline"
		};

		return root;
	}
}
