import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Building } from '../classes/building';
import { Schematic } from "../classes/schematic/schematic";

import { Asset } from 'app/classes/asset';
import { SchematicEquipmentType } from 'app/classes/schematic/schematic-equipment';

@Injectable({
	providedIn: 'root'
})
export class SchematicsService {
	API_URL = 'https://eulgbdw7ml.execute-api.eu-west-2.amazonaws.com/4d/';

	constructor(private apiService: APIService, private http: HttpClient,) { }

	async getModels() {
		const url = `${this.API_URL}/models`;
		const options = this.apiService.getUAOHeaders();
		const response: IGetSchematicsCollection = await this.http
			.get<IGetSchematicsCollection>(url, options)
			.toPromise();



		return response.schematics.map(s => new Schematic(s));
	}

	/*
	getAll(): Promise<Schematic[]> {
		return new Promise(async (resolve) => {
			return this.get(`a=get-schematics`)
				.then(c => {

					const response: Schematic[] = [];

					const { schematics, floors, rooms, equipment, assets } = c;

					schematics.forEach(s => {
						const schematic = new Schematic(
							s,
							floors.filter(f => f.scId === s.id).sort((a, b) => a.floor < b.floor ? -1 : 1),
							rooms.filter(r => r.scId === s.id),
							equipment.filter(e => e.scId === s.id),
							assets.filter(a => a.scId === s.id),
						);
						response.push(schematic);
					});

					console.log('DONE', response);
					resolve(response);
				});
		});
	}
*/
	getForBuilding(building: Building): Promise<any> {
		return new Promise(async (resolve) => {
			return this.get(`a=get-schematics&i=${building.id}`)
				.then(c => {
					resolve(c);
				});
		});
	}

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}


	post(body: any, qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}
}
/*
export class Schematic {
	id: number;
	building: { id: number, title: string };
	isActive: boolean;

	floors: SchematicFloor[] = [];
	constructor(master: any, floors: any[], rooms: any[], equipment: any[], assets: any[]) {
		this.id = master.id;
		this.building = { id: master.building_id, title: master.title };
		this.isActive = master.isActive;
		floors.forEach(f => {
			const floor = new SchematicFloor(f,
				rooms.filter(r => r.scfId === f.scfId),
				equipment.filter(e => e.scfId === f.scfId),
				assets.filter(a => a.scfId === f.scfId),
			);
			this.floors.push(floor);
		});
	}
}

export class SchematicFloor {
	id: number;
	floor: number;
	title: string;

	rooms: SchematicRoom[] = [];

	constructor(data: any, rooms: any[], equipment: any[], assets: any[]) {
		this.id = data.scfId || data.id;
		this.floor = data.floor;
		this.title = data.title;
		rooms.forEach(r => {
			const room = new SchematicRoom(r,
				equipment.filter(e => e.scrId === r.scrId),
				assets.filter(a => a.scrId === r.scrId)
			);
			this.rooms.push(room);
		});
	}
}

export class SchematicRoom {
	id: number;
	type: string;
	title: string;
	equipments: SchematicEquipment[] = [];

	constructor(room: any, equipments: any[], assets: any[]) {
		this.id = room.scrId || room.id;
		this.type = [, 'plant', 'office'][room.room_type_id];
		this.title = room.title;

		equipments.forEach(e => {
			const equipment = new SchematicEquipment({ ...e, assets: assets.filter(a => a.sceId === e.sceId) });
			this.equipments.push(equipment);
		});
	}
}
*/

export interface IGetSchematicsCollection {
	schematics: IGetModelSchematic[],
	models: IGetModelSchematicModel[],
	assets: IGetModelSchematicAsset[]
}

export interface IGetModelSchematic {
	id: number;
	title: string;
	org_id: number;
	isActive: string;
	createdAt: Date;
	buiding: Building;
	floors: IGetModelSchematicFloor[];
}

export interface IGetModelSchematicFloor {
	id: number;
	title: string;
	floor: number;
	rooms: IGetModelSchematicRoom[]
}

export interface IGetModelSchematicRoom {
	id: number;
	title: string;
	equipment: IGetModelSchematicEquipment[]
}

export interface IGetModelSchematicEquipment {
	id: number;
	title: string;
	type: SchematicEquipmentType;
	models?: IGetModelSchematicModel;
	assets: IGetModelSchematicAsset[];
}


export interface IGetModelSchematicAsset {
	id: number;
	asset: { id: number, title: string };
	title: string;
}

export interface IGetModelSchematicModel {
	createdAt: Date;
	description: string;
	id: number;
	title: string;
	scId: number; // schematic
	scTitle: string;
	sceId: number; // equipment
	sceTitle: string;
	scrId: number; // room
	scrTitle: string;
	assets: Asset[];
}
