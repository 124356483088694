import { IGetModelSchematic } from "app/shared/schematics.service";
import { TreeNode } from "primeng/api";
import { Building } from "../building";
import { SchematicEquipment } from "./schematic-equipment";
import { SchematicFloor } from "./schematic-floor";
import { SchematicRoom } from "./schematic-room";

/**
 * A piece of equipment represented as a schematic item
 */
export class Schematic {
	id: number;
	title: string;
	building: Building;
	isActive: boolean;
	createdAt: Date;
	floors: SchematicFloor[];

	constructor(data: IGetModelSchematic) {
		this.id = data.id;
		this.title = data.title;
		this.isActive = !!data.isActive;
		this.createdAt = new Date(data.createdAt);
		if (data.buiding) {
			this.building = new Building(data.buiding);
		}
		if (data.floors) {
			this.floors = data.floors.map(f => new SchematicFloor(f));
		}
	}

	getTreeNode(): TreeNode {
		const nodes: TreeNode[] = [];

		const children: TreeNode[] = this.floors.map(f => f.getTreeNode());

		const root = {
			"label": this.title,
			"data": `schematic-${this.id}`,
			"expandedIcon": "mdi mdi-office-building-outline",
			"collapsedIcon": "mdi mdi-office-building-outline",
			children
		};

		return root;
	}
}
