import { IGetModelSchematicFloor } from "app/shared/schematics.service";
import { TreeNode } from "primeng/api/treenode";
import { SchematicRoom } from "./schematic-room";

export class SchematicFloor {
	id: number;
	title: string;
	floor: number;
	rooms: SchematicRoom[];

	constructor(data: IGetModelSchematicFloor) {
		this.id = data.id;
		this.title = data.title;
		this.floor = data.floor;
		if (data.rooms) {
			this.rooms = data.rooms.map(r => new SchematicRoom(r));
		}
	}

	getTreeNode(): TreeNode {
		const children: TreeNode[] = this.rooms.map(f => f.getTreeNode());

		const root = {
			"label": this.title,
			"data": `schematic-floor-${this.id}`,
			"expandedIcon": "mdi mdi-layers-triple-outline",
			"collapsedIcon": "mdi mdi-layers-triple-outline",
			children
		};

		return root;
	}
}
