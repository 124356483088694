import { IGetModelSchematicRoom } from "app/shared/schematics.service";
import { TreeNode } from "primeng/api/treenode";
import { SchematicEquipment } from "./schematic-equipment";

export class SchematicRoom {
	id: number;
	title: string;
	equipment: SchematicEquipment[];

	constructor(data: IGetModelSchematicRoom) {
		this.id = data.id;
		this.title = data.title;
		this.equipment = data.equipment.map(e => new SchematicEquipment(e));
	}

	getTreeNode(): TreeNode {
		const nodes: TreeNode[] = [];

		const children: TreeNode[] = this.equipment.map(f => f.getTreeNodes());

		const root = {
			"label": this.title,
			"data": `schematic-room-${this.id}`,
			"expandedIcon": "mdi mdi-land-plots-circle",
			"collapsedIcon": "mdi mdi-land-plots-circle",
			children
		};

		return root;
	}
}
