import { IGetModelSchematicEquipment } from "app/shared/schematics.service";
import { TreeNode } from "primeng/api/treenode";
import { SchematicAsset } from "./schematic-asset";

export class SchematicEquipment {
	id: number;
	type: SchematicEquipmentType;
	title: string;
	assets: SchematicAsset[] = [];
	configuration: { monitors: [{ type: string, configuration: any }] };

	constructor(data: IGetModelSchematicEquipment) {
		this.id = data.id;
		this.type = data.type;
		this.title = data.title;
		this.assets = data.assets.map(a => new SchematicAsset(a));
		//this.configuration = data.configuration;
	}

	getTreeNodes(): TreeNode {
		const children: TreeNode[] = this.assets.map(f => f.getTreeNode());

		const root = {
			"label": this.title,
			"data": `schematic-equipment-${this.id}`,
			"expandedIcon": "mdi mdi-fridge-variant-outline",
			"collapsedIcon": "mdi mdi-fridge-variant-outline",
			children
		};

		return root;
	}
}

export type SchematicEquipmentType = ['AHU', 'Boiler', 'Chiller'];
